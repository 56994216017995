<template>
  <article class="ticket" :class="ticket.ticketType == 3 ? 'premium' : 'standard'">
    <div class="ticketTop" @click="openTicketDetails">
      <!--      <div class="ticketTopLeft">-->
      <!--        <h5>-->
      <!--          {{ ticket.firstname }} {{ ticket.lastname }}-->
      <!--        </h5>-->
      <!--      </div>-->
      <div class="ticketTopRight">
        <img :src="config.projectURL + 'assets/images/events/'+ticket.image+'.jpg'">
        <img :id="'miniQRCode'+ticket.id" class="miniQRCode" v-if="openDetails != true">
      </div>
    </div>
    <div class="pad-16 grid-12 ticketBottom">
      <div class="col-sp-12 row-sp-12">
        <h4 class="ticketSecondHeadline">
          Event
        </h4>
        <h5 style="font-weight: 400; font-size: 1.2em;">
          {{ ticket.title }}
        </h5>
        {{ ticket.ticketTitle }}
        <div class="pad-8" />
        <div class="grid-12">
          <div class="col-sp-6 row-sp-12">
            <h4 class="ticketSecondHeadline">
              Datum
            </h4>
            {{ renderDateForJS(ticket.startTime) }}
          </div>
          <div class="col-sp-6 row-sp-12">
            <h4 class="ticketSecondHeadline">
              Beginn
            </h4>
            {{ renderTimeForJS(ticket.startTime) }} Uhr
          </div>
        </div>
        <div class="pad-8" />
        <h3 style="padding-bottom: 8px;">
          Details
        </h3>
        <h6>{{ ticket.title }}</h6>
        <p style="color: #ffffff50; font-size: 0.9em; line-height: 1.5;" v-html="nl2br(ticket.ticketDescription)" />
        <div v-if="ticket.ticketUpgrades === 1">
          <div class="pad-8" />
          <button @click="ticket.upgradeFee = 5.0; ticket.upgradeLevel = 2; checkOutModal = true;" class="button fluid bgcolor-black color-white" v-if="ticket.ticketType == 1">
            Ticket für 5€ auf Deluxe upgraden
          </button>
          <div class="pad-4" />
          <button @click="ticket.upgradeFee = 10.0; ticket.upgradeLevel = 3; checkOutModal = true;" class="button fluid bgcolor-black color-white" v-if="ticket.ticketType == 1">
            Ticket für 10€ auf Premium upgraden
          </button>
          <button @click="ticket.upgradeFee = 10.0; ticket.upgradeLevel = 3; checkOutModal = true;" class="button fluid bgcolor-black color-white" v-if="ticket.ticketType == 2">
            Ticket für 5€ auf Premium upgraden
          </button>
        </div>
        <div v-if="ticket.gaderobe">
          <div class="pad-4" />
          <router-link :to="'/garderobe/'+ticket.eventId">
            <button class="button fluid bgcolor-blue color-white">
              Gaderobenmarke kaufen ({{ ticket.gaderobePrice }} €)
            </button>
          </router-link>
        </div>
      </div>
      <div class="col-sp-4 row-sp-12 t-right">
        <div v-for="friend in friends" :key="friend.id" class="friendItem">
          <img :src="config.projectURL + 'assets/images/users/' + friend.userImg" class="image width-32 circular">
        </div>
      </div>
    </div>
    <div v-if="openDetails" class="ticketDetails">
      <div class="pad-16 t-center">
        <div>
          <img :id="'QRCode'+ticket.id" class="pad-16 br-10 bgcolor-white">
        </div>
        <h4>TicketID: #{{ ticket.id }}</h4>
        <div class="pad-16">
          <button class="button bgcolor-blue color-white" @click="$router.push({name: 'EventDetails', params: { id: ticket.eventId }});">
            Event Details
          </button>
        </div>
      </div>
    </div>
  </article>
  <VueModal :show="checkOutModal" @close="checkOutModal = false" min-size="small" max-size="large">
    <check-out :item="ticket" checkout-type="upgrade" @close="checkOutModal = false" />
  </VueModal>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import Qrious from "qrious";
import CheckOut from "@/components/payment/checkOut";

import {nl2br} from "@/modules/main";

export default {
  name: "ticketSingle",
  components: {CheckOut},
  props: ["ticket"],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      openDetails: false,
      friends: null,
      checkOutModal: false
    }
  },
  mounted() {
    this.config.loading = false
    this.loadMiniQRCode()
    this.getFriends()
  },
  methods: {
    getFriends(){
      const _this = this
      const fD = new FormData()
      fD.append('eventId', this.ticket.eventId)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('eventdetails/getFriends', fD)
          .then(r => {
            if(r.status === 250)
              _this.friends = r.data
          })
    },
    loadMiniQRCode() {
      this.$nextTick(() => {
        new Qrious({
          element: document.getElementById('miniQRCode'+this.ticket.id),
          size: 60,
          value: this.ticket.secureCode,
          background: '#ffffff',
          foreground: '#000000',
          level: 'H',
        })
      })
    },
    openTicketDetails() {
      if(this.openDetails == true){
        this.openDetails = false;
        this.loadMiniQRCode()
      }else{
        this.openDetails = true;
        this.$nextTick(() => {
          new Qrious({
            element: document.getElementById('QRCode'+this.ticket.id),
            size: 250,
            value: this.ticket.secureCode,
            background: '#ffffff',
            foreground: '#000000',
            level: 'H',
          });
        });
      }
    },
    nl2br(str){
      return nl2br(str)
    },
    renderTimeForJS(date){
      const jsTimestamp = date.split(' ')
      const jsDate = jsTimestamp[0].split('-')
      const jsTime = jsTimestamp[1].split(':')
      return jsTime[0] + ':' + jsTime[1]
    },
    renderDateForJS(date){
      const jsTimestamp = date.split(' ')
      const jsDate = jsTimestamp[0].split('-')
      const jsTime = jsTimestamp[1].split(':')
      return jsDate[2] + '.' + jsDate[1] + '.' + jsDate[0]
    },
  }
}
</script>

<style scoped>
.ticket{
  background-color: var(--second-bg-color);
  border-radius: 10px;
  margin-bottom: 32px;
}
.ticket.premium{
  background-color: #E5B80B;
  color: black !important;

}

.ticket.premium h3, .ticket.premium h6, .ticket.premium p, .ticket.premium h5{
  color: black !important;

}


.ticket .ticketTop{
  position: relative;
  cursor: pointer;
  margin-bottom: -24px;
}
.ticket .ticketTop .ticketTopLeft {
  background-color: #ffffff;
  display: inline-block;
  max-width: 48px;
  height: calc(100% - 8px);
  position: absolute;
  left: 0;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border: 2px solid #ffffff;
}
.ticket .ticketTop .ticketTopLeft h5{
  color: #000000;
  display: block;
  height: 100%;
  padding: 0 16px;
  text-align: center;
  font-weight: 800;
  writing-mode: vertical-lr;
}
.ticket .ticketTop .ticketTopRight{
  display: inline-block;
  border-radius: 10px;
  border: 2px solid #ffffff;
  /*max-width: calc(100% - 54px);*/
  /*margin-left: 50px;*/
}
.ticket .ticketTop .ticketTopRight img:not(.miniQRCode){
  width: 100%;
  border-radius: 10px;
  float: right;
}
.ticket .ticketTop .ticketTopRight img.miniQRCode{
  position: absolute;
  bottom: 24px;
  right: 24px;
  border-radius: 10px;
  background: #ffffff;
  padding: 4px;
}
.ticket .ticketBottom{
  padding-top: 40px!important;
}
.ticket .ticketBottom h5{
  font-weight: 800;
  font-size: 1.2em;
  color: #ffffff;
}
.ticket .ticketBottom h3{
  margin: unset !important;
}
.ticket .ticketBottom h6{
  font-weight: 200;
  font-size: 1em;
  margin-bottom: 8px;
  color: rgba(255,255,255,0.6);
}
.ticket .ticketDetails h4{
  font-weight: 800;
  font-size: 1.2em;
  margin-top: 24px;
  margin-bottom: 8px;
}
.friendItem{
  display: inline;
  margin-left: -14px;
}
.friendItem img{
  border: 1px solid #ffffff;
}

.ticketSecondHeadline{
  font-weight: 600;
  font-size: 14px;
  padding-bottom: 6px;
}
</style>