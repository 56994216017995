import { createRouter, createWebHistory } from 'vue-router'

import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";

import lostticket from "@/views/lostticket";
import muttizettel from "@/views/muttizettel";
import profile from "@/views/profile";
import wallet from "@/views/wallet";
import garderobe from "@/views/garderobe";
import create from "@/views/create";
import myEvents from "@/views/myEvents";
import settings from "@/views/settings";
import notifications from "@/views/notifications";
import coupons from "@/views/coupons";
import media from "@/views/media";
import eventFinder from "@/views/eventFinder";
import eventDetails from "@/views/eventDetails";
import tickets from "@/views/tickets";
import login from "@/views/login";
import home from "@/views/home";
import register from "@/views/register";
import navBar from "@/components/navBar/navBar";
import footer from "@/components/footer/footer";
import checkoutFinishedView from "@/views/checkoutFinishedView";

import agb from "@/views/website/agb";
import impressum from "@/views/website/impressum";
import datasecure from "@/views/website/datasecure";

//NICHT LÖSCHEN WIRD FÜR DEN SLIDER GEBRAUCHT UNTEREN BEIDEN
import eventOverview from "@/views/media/eventOverview";
import eventMedia from "@/views/media/eventMedia";
import test from "@/views/test";
import scoreboard from "@/views/scoreboard.vue";
import loungeOrderView from "@/views/loungeOrderView";
import promoterRequest from "@/views/promoterRequest.vue";
import jobs from "@/views/jobs.vue";
import feedback from "@/views/feedback.vue";
import taxiruf from "@/views/taxiruf.vue";
import fundgrube from "@/views/fundgrube.vue";
import album from "@/views/media/album.vue";

const routes = [
  {
    path: '/',
    alias: '/home',
    name: 'Home',
    components: {
      navBar: navBar,
      default: home,
      footer: footer
    },
  },
  {
    path: '/muttizettel',
    name: 'Muttizettel',
    components: {
      navBar: navBar,
      default: muttizettel,
      footer: footer
    },
  },
  {
    path: '/lostticket',
    name: 'Lostticket',
    components: {
      navBar: navBar,
      default: lostticket,
      footer: footer
    },
  },
  {
    path: '/promoter',
    name: 'PromoterAnfrage',
    components: {
      navBar: navBar,
      default: promoterRequest,
      footer: footer
    },
  },
  {
    path: '/jobs',
    name: 'jobs',
    components: {
      navBar: navBar,
      default: jobs,
      footer: footer
    },
  },
  {
    path: '/fundgrube',
    name: 'fundgrube',
    components: {
      navBar: navBar,
      default: fundgrube,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/taxiruf',
    name: 'taxiruf',
    components: {
      navBar: navBar,
      default: taxiruf,
      footer: footer
    },
  },
  {
    path: '/feedback',
    name: 'feedback',
    components: {
      navBar: navBar,
      default: feedback,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/scoreboard',
    name: 'scoreboard',
    components: {
      navBar: navBar,
      default: scoreboard,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/test',
    name: 'Test',
    components: {
      navBar: navBar,
      default: test,
      footer: footer
    },
  },
  {
    path: '/city/:id',
    name: 'Suche',
    components: {
      navBar: navBar,
      default: home,
      footer: footer
    },
  },
  {
    path: '/login',
    name: 'Login',
    components: {
      navBar: navBar,
      default: login,
      footer: footer
    },
    meta: { noAuth: true },
  },
  {
    path: '/register',
    name: 'Register',
    components: {
      navBar: navBar,
      default: register,
      footer: footer
    },
    meta: { noAuth: true },
  },
  {
    path: '/profile',
    name: 'Profil',
    components: {
      navBar: navBar,
      default: profile,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/wallet',
    name: 'Wallet',
    components: {
      navBar: navBar,
      default: wallet,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/garderobe',
    alias: '/garderobe/:id',
    name: 'Garderobe',
    components: {
      navBar: navBar,
      default: garderobe,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/create',
    name: 'Create',
    components: {
      navBar: navBar,
      default: create,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/myEvents',
    name: 'MyEvents',
    components: {
      navBar: navBar,
      default: myEvents,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/tickets',
    name: 'Tickets',
    components: {
      navBar: navBar,
      default: tickets,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/loungeOrder',
    name: 'LoungeOrder',
    components: {
      navBar: navBar,
      default: loungeOrderView,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/notifications',
    name: 'Notifications',
    components: {
      navBar: navBar,
      default: notifications,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/coupons',
    name: 'Coupons',
    components: {
      navBar: navBar,
      default: coupons,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/media',
    name: 'Media',
    components: {
      navBar: navBar,
      default: media,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/media/album/:id',
    name: 'Album',
    components: {
      navBar: navBar,
      default: album,
      footer: footer
    },
    meta: {auth: true},
  },
  {
    path: '/checkoutFinished/:cipher',
    name: 'Checkout',
    components: {
      navBar: navBar,
      default: checkoutFinishedView,
      footer: footer
    },
    props: true
  },
  {
    path: '/settings',
    name: 'Settings',
    components: {
      navBar: navBar,
      default: settings,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/eventFinder',
    name: 'EventFinder',
    components: {
      navBar: navBar,
      default: eventFinder,
      footer: footer
    }
  },
  {
    path: '/eventDetails/:id',
    name: 'EventDetails',
    components: {
      navBar: navBar,
      default: eventDetails,
      footer: footer
    }
  },
  {
    name: '404',
    path: '/:pathMatch(.*)*',
    alias: '/404',
    redirect: () => {
      return { path: '/home' }
    },
  },
  {
    path: '/agb',
    name: 'agb',
    components: {
      navBar: navBar,
      default: agb,
      footer: footer
    }
  },
  {
    path: '/impressum',
    name: 'impressum',
    components: {
      navBar: navBar,
      default: impressum,
      footer: footer
    }
  },
  {
    path: '/datenschutz',
    name: 'datenschutz',
    components: {
      navBar: navBar,
      default: datasecure,
      footer: footer
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const auth = authStore()
  const config = configStore()

  config.menuModal = false

  window.scrollTo(0, 0)
  window.onscroll = function () {}

  if (to.matched.some(record => record.meta.auth)) {
    auth.update()
        .then(function (resolved) {
          config.loading = true
            next()
        })
        .catch(function (e) {
          next({
            path: '/login',
            query: { redirect: to.fullPath }
          })
        })
  } else if (to.matched.some(record => record.meta.noAuth)) {
    auth.update()
        .then(() => {
          next({
            name: 'Home'
          })
        })
        .catch((e)=> {
          next()
        })
        .finally(function () {
          config.loading = true
        })
  } else {
    auth.update()
        .catch(e =>{
          console.log(e)
        })
        .finally(() => {
          config.loading = true
          next()
        })
  }
})

let history = []
router.afterEach((to, from) => {
  configStore().getAd(to.path)
  if(to.path === history.at(-2)?.path){
    to.meta.transition = history.at(-2).direction === 'slide-right' ? 'slide-left' : 'slide-right'
    history.pop()
  }else{
    to.meta.transition = to.path.split('/').length < from.path.split('/').length ? 'slide-right' : 'slide-left'
    history.push({path: to.path, direction: to.meta.transition})
  }
})

export default router
