<template>
  <div>
    <main class="content-500" v-if="step === 0">
      <div class="t-center pad-16">
        <p class="t-center" style="font-size: 4.4em">
          🎉
        </p>
        <h3 style="font-size: 1.8em;" class="f-bold color-white">
          Bei Macher Events arbeiten
        </h3>
        <h4 style="font-size: 1.1em;" class="color-blue">
          Mega, dass du bei uns arbeiten möchtest! <br>Bitte klicke dich kurz durch. <br>Das Ganze dauert weniger als 1 Minute.
        </h4>
      </div>
      <div class="pad-8" v-if="!successMessage">
        <button class="button bgcolor-blue color-white fluid" @click="step = 1">
          Los gehts! 🚀
        </button>
      </div>
    </main>
    <main class="content-500" v-if="step === 1">
      <div class="t-center pad-16">
        <h3 style="font-size: 1.2em;" class="f-normal color-white">
          <span class="f-bold">Wo möchtest du gerne arbeiten?</span><br><br>
          Bei uns gibt es verschiedene Jobs in allen Bereichen - Vollzeit, Teilzeit, Minijob, Ausbildung, duales Studium oder auch als Freelancer.
        </h3>
        <div class="pad-8" />
        <button @click="location = 'office'; step = 2" class="pad-16 br-10 bgcolor-second color-white button fluid" style="font-size: 1.1em">
          <span style="font-size: 1.8em;">🏢</span><br>
          Im Büro <br><span style="font-size: 0.8em; font-weight: normal">(nur in Braunschweig möglich)</span>
        </button>
        <div class="pad-8" />
        <button @click="location = 'events'; step = 2" class="pad-16 br-10 bgcolor-second color-white button fluid" style="font-size: 1.1em">
          <span style="font-size: 1.8em;">🎉</span><br>
          Auf unseren Veranstaltungen<br><br>
        </button>
      </div>
    </main>
    <main class="content-500" v-if="step === 2">
      <div class="t-center pad-16">
        <div v-if="job !== 'Fotograf/Videograf' && job !== 'Deejay' && job !== 'Akteur'">
          <h3 style="font-size: 1.2em;" class="f-normal color-white">
            <span class="f-bold">Welcher Job interessiert dich?</span>
          </h3>
          <div class="pad-8" />
          <button @click="job = 'Bürokauffrau/mann'; step = 3" class="pad-16 br-10 bgcolor-second color-white button fluid ">
            <span style="font-size: 1.6em; padding-right: 8px;">💻</span> Bürokauffrau/mann
          </button>
          <div class="pad-4" />
          <button @click="job = 'Eventcrew'; step = 3" class="pad-16 br-10 bgcolor-second color-white button fluid ">
            <span style="font-size: 1.6em; padding-right: 8px;">🪩</span> Eventcrew
          </button>
          <div class="pad-4" />
          <button @click="job = 'Deejay'" class="pad-16 br-10 bgcolor-second color-white button fluid ">
            <span style="font-size: 1.6em; padding-right: 8px;">🎧</span> Deejay
          </button>
          <div class="pad-4" />
          <button @click="job = 'Fotograf/Videograf'" class="pad-16 br-10 bgcolor-second color-white button fluid ">
            <span style="font-size: 1.6em; padding-right: 8px;">📷</span> Fotograf/Videograf
          </button>
          <div class="pad-4" />
          <button @click="job = 'Akteur'" class="pad-16 br-10 bgcolor-second color-white button fluid ">
            <span style="font-size: 1.6em; padding-right: 8px;">🕺</span> Akteur (Tänzer & Co.)
          </button>
          <div class="pad-4" />
          <button @click="job = 'Content Creator'; step = 3" class="pad-16 br-10 bgcolor-second color-white button fluid ">
            <span style="font-size: 1.6em; padding-right: 8px;">🤳🏼</span> Content Creator
          </button>
          <div class="pad-4" />
          <button @click="job = 'Vertriebler'; step = 3" class="pad-16 br-10 bgcolor-second color-white button fluid ">
            <span style="font-size: 1.6em; padding-right: 8px;">💰</span> Vertriebler
          </button>
          <div class="pad-4" />
          <button @click="job = 'Grafiker'; step = 3" class="pad-16 br-10 bgcolor-second color-white button fluid ">
            <span style="font-size: 1.6em; padding-right: 8px;">🎨</span> Grafiker
          </button>
        </div>
        <div v-else>
          <div v-if="experience === ''">
            <h3 style="font-size: 1.2em;" class="f-normal color-white">
              <span class="f-bold">Machst du dies bereits länger und hast Erfahrung darin?</span>
            </h3>
            <div class="pad-8" />
            <button @click="experience = 'Ja';" class="pad-16 br-10 bgcolor-second color-white button fluid" style="font-size: 1.1em">
              Ja
            </button>
            <div class="pad-8" />
            <button @click="experience = 'Nein'; step = 3" class="pad-16 br-10 bgcolor-second color-white button fluid" style="font-size: 1.1em">
              Nein
            </button>
          </div>
          <div v-else>
            <h3 style="font-size: 1.2em;" class="f-normal color-white">
              <span class="f-bold">
                Nenne bitte ein paar Veranstaltungsorte, wo du zurzeit aktiv bist/warst.
              </span>
            </h3>
            <div class="pad-8" />
            <div class="field">
              <textarea rows="4" placeholder="Nenne einige Beispiele..." v-model="experiencePortfolio" />
            </div>
            <div class="pad-4" />
            <button v-if="experiencePortfolio" @click="step = 3;" class="pad-16 br-10 bgcolor-blue color-white button fluid" style="font-size: 1.1em">
              Weiter
            </button>
          </div>
        </div>
      </div>
    </main>
    <main class="content-500" v-if="step === 3">
      <div class="t-center pad-16">
        <div v-if="driver === ''">
          <p class="t-center" style="font-size: 4.4em">
            🚗
          </p>
          <h3 style="font-size: 1.2em;" class="f-normal color-white">
            <span class="f-bold">Führerschein vorhanden?</span><br><br>
          </h3>
          <div class="pad-8" />
          <button @click="driver = 'Ja';" class="pad-16 br-10 bgcolor-second color-white button fluid" style="font-size: 1.1em">
            Ja
          </button>
          <div class="pad-8" />
          <button @click="driver = 'Nein'; step = 4" class="pad-16 br-10 bgcolor-second color-white button fluid" style="font-size: 1.1em">
            Nein
          </button>
        </div>
        <div v-else>
          <p class="t-center" style="font-size: 4.4em">
            🚙
          </p>
          <h3 style="font-size: 1.2em;" class="f-normal color-white">
            <span class="f-bold">Hast du ein eigenes Auto?</span><br><br>
          </h3>
          <div class="pad-8" />
          <button @click="car = 'Ja'; step = 4" class="pad-16 br-10 bgcolor-second color-white button fluid" style="font-size: 1.1em">
            Ja
          </button>
          <div class="pad-8" />
          <button @click="car = 'Nein'; step = 4" class="pad-16 br-10 bgcolor-second color-white button fluid" style="font-size: 1.1em">
            Nein
          </button>
        </div>
      </div>
    </main>
    <main class="content-500" v-if="step === 4">
      <div class="t-center pad-16">
        <h3 style="font-size: 1.2em;" class="f-normal color-white">
          <span class="f-bold">Zu deiner Person:</span>
        </h3>
        <div class="pad-8" />
        <form @submit.prevent="submitJobRequest()" class="pad-8">
          <div class="field simple pad-8">
            <label for="firstname">Vorname:</label>
            <input required placeholder="Vorname" type="text" id="firstname" name="firstname" v-model="firstname">
          </div>
          <div v-if="!auth.isAuth">
            <div class="field simple pad-8">
              <label for="firstname">E-Mail-Adresse:</label>
              <input required placeholder="max@mustermann.de" type="email" id="email" name="email" v-model="email">
            </div>
            <div class="field simple pad-8">
              <label for="firstname">Telefonnummer:</label>
              <input required placeholder="+49123456789" type="tel" id="phone" name="phone" v-model="phone">
            </div>
          </div>
          <div class="field simple pad-8">
            <label for="city">Wohnort: </label>
            <input required placeholder="Wohnort" id="city" name="city" v-model="city">
          </div>
          <div class="field simple pad-8">
            <label for="instagram">Instagram-Name: </label>
            <input placeholder="Instagram-Name" id="instagram" name="instagram" v-model="instagram">
          </div>
          <div class="field simple pad-8">
            <label for="about">Gib uns gerne weitere Infos über dich:</label>
            <textarea placeholder="..." id="about" name="about" v-model="about" />
          </div>
          <div class="pad-8" v-if="!successMessage">
            <button class="button bgcolor-blue color-white fluid">
              Absenden 🚀
            </button>
            <p style="font-size: 0.8em; margin-top: 8px;">
              Mit dem Absenden bestätigst du, dass du bezüglich dieser Anfrage kontaktiert werden darfst. Es gelten unsere Datenschutzbestimmungen.
            </p>
          </div>
        </form>
        <div v-if="successMessage" class="t-center">
          <span style="display: inline-block;" class="green label">Vielen Dank! Wir werden uns in den nächsten 3 Werktagen bei dir melden.</span>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "jobs",
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return{
      step: 0,
      location: '',
      job: '',
      driver: '',
      car: '',
      experience: '',
      experiencePortfolio: '',
      successMessage: false,
      firstname: '',
      city: '',
      instagram: '',
      about: '',
      email: this.user.email,
      phone: this.user.phone
    }
  },
  methods:{


    submitJobRequest(){
      const _this = this
      const fD = new FormData()
      fD.append('location', this.location)
      fD.append('job', this.job)
      fD.append('driver', this.driver)
      fD.append('car', this.car)
      fD.append('experience', this.experience)
      fD.append('experiencePortfolio', this.experiencePortfolio)
      fD.append('firstname', this.firstname)
      fD.append('city', this.city)
      fD.append('instagram', this.instagram)
      fD.append('about', this.about)
      fD.append('email', this.email)
      fD.append('phone', this.phone)
      this.axios.post('jobs/sendMessage', fD)
          .then((response) => {
            if(response.status === 250){
              _this.successMessage = true
            }
          })
    }
  }
}
</script>

<style scoped>
  label{
    display: block;
    font-weight: normal;
    margin-bottom: 4px;
  }
  .field input,
  .field textarea{
    padding: 12px;
    border: none;
    border-bottom: 2px solid rgba(0,0,0,0.2);
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    border-radius: 10px;
    background-color: rgba(0,0,0,0.4);
    box-sizing: border-box;
    color: #ffffff;
  }
  .green.label{
    color: #08cc77;
    background-color: #32ee9b33;
  }
</style>