<template>
  <div>
    <main class="content-500">
      <div class="t-center pad-16">
        <h3 style="font-size: 2em;" class="f-bold color-white">
          ⭐<br>Scoreboard
        </h3>
      </div>
      <section class="pad-16">
        <table style="width: 100%;">
          <thead>
            <tr style="font-weight: bold;">
              <td />
              <td>Nutzer</td>
              <td class="t-right">
                Punkte
              </td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(scoreUser ,index) in scoreboard" :key="scoreUser.id">
              <td>{{ index+1 }}</td>
              <td>
                <img :src="config.projectURL + 'assets/images/users/' + scoreUser.avatar" style="width: 32px; height: 32px; vertical-align: -10px; margin-right: 4px; background-color: #ffffff20; border-radius: 90%;">
                <span>{{ scoreUser.firstname }} {{ scoreUser.lastname }}</span>
              </td>
              <td class="t-right">
                {{ scoreUser.totalPoints }}
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </main>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "scoreboard",
  title: "Scoreboard",
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return{
      scoreboard: [],
    }
  },
  mounted() {
    this.getScoreboard()
  },
  methods:{
    getScoreboard(){
      const _this = this
      this.axios.post('punktesystem/getData')
          .then((response) => {
            if(response.status === 250){
              _this.scoreboard = response.data;
            }
          })
    },
  }
}
</script>
<style scoped>
tr td{
  padding: 8px;
  background-color: #ffffff05;
}
tr:nth-child(even) {
  background-color: #ffffff10;
}
</style>
