<template>
  <div>
    <main class="content-500">
      <div class="t-center pad-16">
        <h3 style="font-size: 2em;" class="f-bold color-white">
          🔍<br>Fundgrube
        </h3>
        <h4 style="font-size: 1.3em;" class="color-blue">
          👉 Du hast etwas auf einem Event verloren?
        </h4>
        <p class="t-center" style="margin-top: 8px;">
          Fülle das folgende Formular aus und wir melden uns bei dir!
        </p>
      </div>
      <form v-if="!successMessage" @submit.prevent="submitForm()" class="pad-8">
        <div class="field simple pad-8">
          <label style="padding-bottom: 6px;">Event auswählen</label>
          <select v-model="eventId" required>
            <option value="-1" disabled>
              Event...
            </option>
            <option v-for="event in events" :key="event.id" :value="event.id">
              {{ event.startTime.split(' ')[0].split('-')[2] + '.' + event.startTime.split(' ')[0].split('-')[1] + '.' + event.startTime.split(' ')[0].split('-')[0] + ' - ' + event.title }}
            </option>
          </select>
        </div>
        <div class="field simple pad-8">
          <label style="padding-bottom: 6px;">Kategorie auswählen</label>
          <select v-model="category" required>
            <option value="-1" disabled>
              Kategorie...
            </option>
            <option value="Ausweis">
              Ausweis
            </option>
            <option value="Schmuck">
              Schmuck
            </option>
            <option value="Geld">
              Geld
            </option>
            <option value="Brille">
              Brille
            </option>
            <option value="Handy">
              Handy
            </option>
            <option value="Sonstiges">
              Sonstiges
            </option>
          </select>
        </div>
        <div class="field simple pad-8" v-if="category === 'Sonstiges'">
          <label for="description">Beschreibung</label>
          <textarea rows="4" required placeholder="Gebe uns eine genauere Beschreibung von deinem Verlust" id="description" name="description" v-model="description" />
        </div>
        <div class="field simple pad-8">
          <label for="firstname">Vorname:</label>
          <input placeholder="Vorname" type="text" id="firstname" name="firstname" required v-model="firstname">
        </div>
        <div class="field simple pad-8">
          <label for="lastname">Nachname:</label>
          <input placeholder="Nachname" type="text" id="lastname" name="lastname" required v-model="lastname">
        </div>
        <div class="field simple pad-8">
          <label for="phone">Deine Handynummer:</label>
          <input placeholder="Telefonnummer" type="tel" id="phone" name="phone" required v-model="phone">
        </div>
        <div class="field simple pad-8">
          <label for="email">Deine E-Mail-Adresse: </label>
          <input placeholder="E-Mail-Adresse" type="email" id="email" name="email" required v-model="email">
        </div>
        <div class="pad-8" v-if="!successMessage">
          <button class="button bgcolor-blue color-white fluid">
            Absenden
          </button>
          <p style="font-size: 0.8em; margin-top: 8px;">
            Mit dem Absenden bestätigst du, dass du bezüglich dieser Anfrage kontaktiert werden darfst. Es gelten unsere Datenschutzbestimmungen.
          </p>
        </div>
      </form>
      <div v-if="successMessage" class="t-center">
        <span style="display: inline-block;" class="green label">Deine Nachricht wurde erfolgreich verschickt.<br>Wir werden uns in kürze bei dir melden.</span>
      </div>
    </main>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "fundgrube",
  title: "Fundgrube",
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return{
      phone: this.user.phone,
      email: this.user.email,
      firstname: this.user.firstname,
      lastname: this.user.lastname,
      description:'',
      events: [],
      eventId: '',
      category: '',
      successMessage: false,
    }
  },
  mounted() {
    this.loadEvents()
  },
  methods:{
    submitForm(){
      const _this = this
      const fD = new FormData()
      fD.append('firstname', this.firstname)
      fD.append('lastname', this.lastname)
      fD.append('phone', this.phone)
      fD.append('email', this.email)
      fD.append('description', this.description)
      fD.append('eventId', this.eventId)
      fD.append('category', this.category)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('fundgrube/sendMessage', fD)
          .then((response) => {
            if(response.status === 250){
              _this.successMessage = true
            }
          })
    },
    loadEvents(){
      const _this = this
      this.axios.post('fundgrube/getEvents')
          .then((response) => {
            if(response.status === 250){
              _this.events = response.data;
            }
          })
    },
  }
}
</script>

<style scoped>
  label{
    display: block;
    font-weight: normal;
    margin-bottom: 4px;
  }
  .field input,
  .field textarea,
  .field select{
    padding: 12px;
    border: none;
    border-bottom: 2px solid rgba(0,0,0,0.2);
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    border-radius: 10px;
    background-color: rgba(0,0,0,0.4);
    box-sizing: border-box;
    color: #ffffff;
  }
  .green.label{
    color: #08cc77;
    background-color: #32ee9b33;
  }
</style>