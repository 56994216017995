<template>
  <main class="content-500 coupons">
    <div v-if="page === 'overview'">
      <section class="pad-16" style="cursor: pointer;">
        <div class="pad-16 br-10" style="margin-top: 16px; background-color: var(--second-bg-color);">
          <div class="grid-12" style="margin-top: 24px; margin-bottom: 16px;">
            <div class="col-sp-8 row-sp-12">
              <h1 style="font-size: 1.8em; margin-top: 0;">
                Deine Prämienpunkte
              </h1>
              <p style="font-size: 0.9em; color: #ffffff95">
                Du erhälts beim Kauf von Tickets, Getränken, Lounges etc. Prämienpunkte, welche du in unserem Prämienshop einlösen kannst.
              </p>
            </div>
            <div class="col-sp-4 row-sp-12 t-right">
              <div style="display: inline-block; padding: 16px 8px;" class="bgcolor-blue color-white br-10 t-center">
                <h4 class="f-bold" style="font-size: 1.2em;">
                  {{ userPoints }}
                </h4>
                <h5 style="font-size: 0.9em;">
                  Punkte
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="bgcolor-blue" style="width: calc(100% - 32px); margin: -24px auto 0 auto;">
        <div class="pad-16" style="cursor:pointer;" @click="page = 'shop';">
          <h6 style="font-size: 1.2em; text-align: center;">
            Zum Prämienshop
          </h6>
        </div>
      </section>
      <div class="title" style="margin-top: 32px;">
        <h1>Gutscheine</h1>
        <h2>Exklusiv für dich</h2>
      </div>
      <section class="pad-16">
        <coupon-element v-for="coupon in coupons" :coupon="coupon" :key="coupon.id" />
        <div v-if="!coupons.length" class="t-center">
          <p class="color-white">
            Keine Gutscheine vorhanden.
          </p>
        </div>
      </section>
    </div>
    <div v-if="page === 'shop'">
      <div class="pad-16">
        <button class="button" @click="page = 'overview'">
          Zurück
        </button>
      </div>
      <div class="title" style="margin-top: 32px;">
        <h1>Prämienshop</h1>
        <h2>Löse jetzt deine Punkte ein!</h2>
      </div>
      <section class="bgcolor-blue br-10" style="width: calc(100% - 32px); margin: 0 auto;">
        <div class="pad-16" style="cursor:pointer;" @click="page = 'shop';">
          <div class="grid-12">
            <div class="col-sp-6 row-sp-12">
              <h6 style="font-size: 1.2em;">
                Deine Punkte:
              </h6>
            </div>
            <div class="col-sp-6 row-sp-12 t-right">
              <h4 class="f-bold" style="font-size: 1.2em;">
                {{ userPoints }}
              </h4>
            </div>
          </div>
        </div>
      </section>
      <section class="pad-16">
        <div v-for="praemie in praemien" :key="praemie.id" class="bgcolor-second pad-16 br-10" style="margin-bottom: 8px;">
          <h6 style="font-size: 1.2em;">
            {{ praemie.title }}
          </h6>
          <p>{{ praemie.description }}</p>
          <h6 class="t-right" style="font-size: 1.2em;">
            Für {{ praemie.price }} Punkte
          </h6>
          <div class="pad-8" />
          <div>
            <button v-if="userPoints >= praemie.price" @click="buy(praemie.id)" class="button bgcolor-white color-black fluid">
              {{ praemie.price }} Punkte einlösen
            </button>
            <button v-else disabled class="button bgcolor-white color-black fluid">
              Nicht genügend Punkte
            </button>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import couponElement from "@/components/coupons/coupon";
import {aSyncData} from "@/modules/main";


export default {
  name: "Coupons",
  title: "Coupons",
  components: {
    couponElement,
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      coupons: [],
      userPoints: '',
      page: 'overview',
      praemien: []
    }
  },
  mounted() {
    this.config.loading = false
    this.getCoupons()
    this.getPoints()
    this.getPraemien()
  },
  methods: {
    getPoints(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('coupon/getPoints', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              _this.userPoints = response.data.points
            }
          })
    },
    getPraemien(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('coupon/getPraemien', fD)
          .then((response) => {
            if(response.status === 250){
              _this.praemien = response.data
            }
          })
    },
    buy(id){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', id)
      this.axios.post('coupon/buyCoupon', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              _this.getPoints()
              _this.getCoupons()
              _this.page = 'overview'
            }
          })
    },
    getCoupons(){
      aSyncData('coupon/getCoupons')
          .then(r => {
            if(r.status === 250){
              this.coupons = r.data
            }
          })
    },
  }
}
</script>

<style>
.coupons .title h1{
  margin-top: 16px;
  font-size: 2em;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
}
.coupons .title h2{
  font-size: 1.1em;
  font-weight: 200;
  color: rgba(255,255,255,0.8);
  text-align: center;
  margin-bottom: 32px;
}

</style>