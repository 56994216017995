<template>
  <main class="content-500" id="tickets">
    <section class="t-center" v-if="user.lounges.length">
      <h1>Meine Lounges</h1>
      <h2>Übersicht der Lounges</h2>
    </section>
    <section class="pad-16" id="newTickets" v-if="user.lounges.length">
      <lounge-single v-for="lounge in user.lounges" :lounge="lounge" :key="lounge.id" />
    </section>
    <section class="t-center">
      <h1>Meine Tickets</h1>
      <h2>Übersicht der Tickets</h2>
    </section>
    <section class="pad-16" @click="openRangDetails()" style="cursor: pointer;">
      <div class="pad-16 br-10" style="margin-top: 16px; background-color: var(--second-bg-color);">
        <div class="grid-12">
          <div class="col-sp-4 row-sp-12" style="background-color: #00000020; border-radius: 10px; height: 8px; margin-top: 6px;">
            <div class="dot" />
          </div>
          <div class="col-sp-8 row-sp-12 t-right">
            <p class="color-white">
              {{ currentDateTime }}
            </p>
          </div>
        </div>
        <div class="grid-12" style="margin-top: 40px; margin-bottom: 16px;">
          <div class="col-sp-8 row-sp-12">
            <h1 style="font-size: 1.8em; margin-top: 0;">
              {{ rang.rangName }}
            </h1>
            <h3>{{ user.firstname }} {{ user.lastname }}</h3>
          </div>
          <div class="col-sp-4 row-sp-12 t-right">
            <div style="display: inline-block; padding: 16px 8px;" class="bgcolor-blue color-white br-10 t-center">
              <h4 class="f-bold" style="font-size: 1.2em;">
                {{ userPoints }}
              </h4>
              <h5 style="font-size: 0.9em;">
                Punkte
              </h5>
            </div>
          </div>
        </div>
        <div v-if="rangDetails" style="padding-bottom: 16px;">
          <h4 class="f-bold" style="margin-bottom: 8px;">
            Deine Vorteile:
          </h4>
          <p class="color-white" v-html="nl2br(rang.goodies)" />
        </div>
      </div>
    </section>
    <section v-if="checkIn" class="bgcolor-blue" style="width: calc(100% - 32px); margin: -24px auto 0 auto;">
      <div class="pad-16">
        <b class="f-bold" style="font-size: 0.9em;">Eingecheckt beim Event:</b>
        <h6 style="font-size: 1.2em;">
          {{ checkIn.title }}
        </h6>
        <p class="t-right" style="font-size: .8em; color: #ffffff60">
          CheckIn: {{ renderTimeStampForJS(checkIn.timestamp) }} Uhr
        </p>
      </div>
    </section>
    <section class="pad-16" id="newTickets">
      <ticketSingle v-for="ntitems in newTickets" :ticket="ntitems" :key="ntitems.id" />
      <p class="t-center color-white" v-if="newTickets.length == 0">
        Du hast aktuell keine Tickets
      </p>
    </section>
    <section class="pad-16" id="oldTickets" v-if="oldTickets !== ''">
      <div class="t-center">
        <h3>Alte Tickets</h3>
      </div>
      <ticketSingle v-for="otitems in oldTickets" :ticket="otitems" :key="otitems.id" />
      <p class="t-center color-white" v-if="oldTickets.length == 0">
        Du hast keine alten Tickets
      </p>
    </section>
  </main>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import ticketSingle from "@/components/tickets/ticketSingle";
import {stringToDate,nl2br} from "@/modules/main";
import LoungeSingle from "@/components/tickets/loungeSingle";

export default {
  name: "Tickets",
  title: "Tickets",
  components: {
    LoungeSingle,
    ticketSingle
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      tickets: [],
      currentDateTime: this.getCurrentDateTime(),
      rang: {goodies: 'Du hast noch keine Vorteile.',rangName: 'Gast'},
      userPoints: 0,
      rangDetails: false,
      checkIn: '',

    }
  },
  computed: {
    newTickets() {
      return this.tickets.filter(e => {
        try {
          return parseInt(e.status) === 0 && stringToDate(e.endTime).getTime() >= (new Date()).getTime()
        }catch (e) {
          return false
        }
      })
    },
    oldTickets(){
      return this.tickets.filter(e => {
        try {
          return parseInt(e.status) !== 0 || stringToDate(e.endTime).getTime() < (new Date()).getTime()
        }catch (e) {
          return false
        }
      })
    }
  },
  mounted() {
    this.config.loading = false
    this.getTickets()
    this.getRang()
    this.getCheckIn()
    this.checkCheckIn()
    this.getPoints()
    setInterval(() => {
      this.currentDateTime = this.getCurrentDateTime();
    }, 1000);
  },
  methods: {
    nl2br(str){
      return nl2br(str)
    },
    openRangDetails(){
      if(this.rangDetails == true){
        this.rangDetails = false;
      }else {
        this.rangDetails = true;
      }
    },
    getCurrentDateTime() {
      const now = new Date();
      const dateOptions = {
        year: "numeric",
        month: "long",
        day: "numeric"
      };
      const timeOptions = {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false
      };
      const date = now.toLocaleDateString(undefined, dateOptions);
      const time = now.toLocaleTimeString(undefined, timeOptions);
      return `${date} ${time}`;
    },
    getTickets(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('tickets/getTickets', fD)
          .then((response) => {
            if(response.status === 250){
              console.log(response.data)
              _this.tickets = response.data
            }
          })
    },
    getRang(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('tickets/getRang', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              _this.rang = response.data.rang
            }
          })
    },
    getPoints(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('tickets/getPoints', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              _this.userPoints = response.data.points
            }
          })
    },
    getCheckIn(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('checkin/getCheckIn', fD)
          .then((response) => {
            if(response.status === 250){
              console.log(response)
              _this.checkIn = response.data
            }
          })
    },
    checkCheckIn(){
      if(this.$route.query.id){
        const _this = this
        const fD = new FormData()
        fD.append('sessionID', this.auth.sessionID)
        fD.append('id', this.$route.query.id)
        this.axios.post('checkin/checkInCheck', fD)
            .then((response) => {
              if(response.status === 250){
                console.log(response)
                _this.getCheckIn()
                _this.getRang()
                _this.getPoints()
              }
            })
      }
    },
    renderTimeStampForJS(date){
      const jsTimestamp = date.split(' ')
      const jsDate = jsTimestamp[0].split('-')
      const jsTime = jsTimestamp[1].split(':')
      return jsDate[2] + '.' + jsDate[1] + '.' + jsDate[0] + ' ' + jsTime[0] + ':' + jsTime[1]
    },
  }
}
</script>

<style>
main#tickets{
  padding-bottom: 80px;
  padding-top: 0!important;
}
main#tickets h1{
  margin-top: 64px;
  font-size: 2em;
  font-weight: 800;
  text-transform: uppercase;
}
main#tickets h2{
  font-size: 1.1em;
  font-weight: 200;
  color: rgba(255,255,255,0.8);
  margin-bottom: -16px;
}
main#tickets h3{
  font-size: 1.2em;
  font-weight: 200;
  color: rgba(255,255,255,0.8);
  margin-bottom: -16px;
}
main #newTickets{
  margin-top: 32px;
  padding: 32px 16px;
}
main #oldTickets h3{
  margin-bottom: 32px;
}
.dot {
  width: 32px;
  height: 8px;
  background-color: #d2420d;
  border-radius: 10px;
  position: relative;
  animation: bounce 1s linear infinite alternate; /* Hier wird die Animation definiert */
}

@keyframes bounce {
  0% {
    left: 0;
  }
  100% {
    left: calc(100% - 20px);
  }
}
</style>