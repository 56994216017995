<template>
  <main className="content-500" id="media">
    <section class="t-center">
      <h1>Mediengalerie</h1>
      <h2>Erinnerung an die besten Events</h2>
    </section>
    <section style="padding: 8px">
      <div class="grid-12">
        <router-link class="col-sp-6 row-sp-12" v-for="album in albums" :key="album.id" :to="`/media/album/${album.id}`" style="color:white;cursor: pointer; background-color: #00000040;border-radius: 12px;overflow: hidden">
          <div>
            <img style="width: 100%;height: auto" :src="`${config.projectURL}assets/images/media/${album.cover}_thumbnail.jpg`">
            <div style="padding: 8px;">
              <h3 style="text-align: center;font-size: 18px">
                {{ album.title }}
              </h3>
              <p style="white-space: pre-line;padding: 12px; font-size: 0.9em;">
                {{ album.description }}
              </p>
            </div>
          </div>
        </router-link>
      </div>
    </section>
  </main>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {aSyncData} from "@/modules/main";


export default {
  name: "MediaView",
  title: "Media",

  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      albums:[],
    }
  },
  mounted() {
    this.config.loading = false
    this.loadAlbums()
  },
  methods: {
    loadAlbums(){
      aSyncData('media/getAlbums')
          .then(r => {
            console.log(r.data)
            if(r.status === 250){
              this.albums = r.data
              console.log(this.albums)
            }
          })
    },
  }
}
</script>

<style>
main#media h1 {
  margin-top: 24px;
  font-size: 2em;
  font-weight: 800;
  text-transform: uppercase;
}
main#media h2 {
  font-size: 1.1em;
  font-weight: 200;
  color: rgba(255,255,255,0.8);
  margin-bottom: 24px;
}
</style>