
<template>
  <div style="max-width: 1536px;color:white;padding: 0 16px;margin: 250px auto 0" class="">
    <h2 class="text-4xl font-semibold">Impressum</h2>
    <h3 class="text-2xl pt-4" style="padding-top: 16px">Angaben gemäß § 5 TMG</h3>

    <p class="">
      Macher Events<br>
      Pippelweg 70<br>
      38118 Braunschweig<br>
    </p>
    <div class="p-1.5" style="padding: 6px"></div>
    <p><b>Vertreten durch:</b><br>Joris Ameln</p>
    <h3 class="text-2xl pt-4 relative group" style="padding-top: 16px;position: relative">
      Kontakt:
      <span class="absolute inset-x-0 bottom-1 bg-purple-600/60 h-2 w-16 rounded-lg z-[-1]" style="background-color: #ca0001;opacity:60%;width: 100%;border-radius:4px;position: absolute;bottom: 4px;height: 8px;left:0;z-index: -1"></span>
    </h3>
    <p class="">
      Telefon: +49 1523 6226551<br/>
      E-Mail: info@macher-events.de
    </p>
    <h3 class="text-2xl pt-4 relative group" style="padding-top: 16px">
      Umsatzsteuer-ID
      <span class="absolute inset-x-0 bottom-1 bg-purple-600/60 h-2 w-40 rounded-lg z-[-1]" style="background-color: #ca0001;opacity:60%;width: 100%;border-radius:4px;position: absolute;bottom: 4px;height: 8px;left:0;z-index: -1"></span>
    </h3>
    <p class="">
      Umsatzsteuer-Identifikationsnummer gemäß §27a<br>
      Umsatzsteuergesetz: DE328203638
    </p>
    <h3 class="text-2xl pt-4 relative group" style="padding-top: 16px">
      EU-Streitschlichtung
      <span class="absolute inset-x-0 bottom-1 bg-purple-600/60 h-2 w-52 rounded-lg z-[-1]" style="background-color: #ca0001;opacity:60%;width: 100%;border-radius:4px;position: absolute;bottom: 4px;height: 8px;left:0;z-index: -1"></span></h3>
    <p class="">
      Die Europäische Kommission stellt eine Plattform zur
      Online-Streitbeilegung (OS) bereit: https://ec.europa.eu/
      consumers/odr.
      Unsere E-Mail-Adresse finden Sie oben im Impressum.
    </p>
    <h3 class="text-2xl pt-4 relative group" style="padding-top: 16px">
      Verbraucherstreitbeilegung/ Universalschlichtungsstelle
      <span class="absolute inset-x-0 bottom-1 bg-purple-600/60 h-2 w-52 rounded-lg z-[-1]" style="background-color: #ca0001;opacity:60%;width: 100%;border-radius:4px;position: absolute;bottom: 4px;height: 8px;left:0;z-index: -1"></span></h3>
    <p class="">
      Wir sind nicht bereit oder verpflichtet, an
      Streitbeilegungsverfahren vor einer
      Verbraucherschlichtungsstelle teilzunehmen.
    </p>
    <h3 class="text-2xl pt-4 relative group" style="padding-top: 16px">
      Haftung für Inhalte
      <span class="absolute inset-x-0 bottom-1 bg-purple-600/60 h-2 w-36 rounded-lg z-[-1]" style="background-color: #ca0001;opacity:60%;width: 100%;border-radius:4px;position: absolute;bottom: 4px;height: 8px;left:0;z-index: -1"></span></h3>
    <p class="">
      Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für
      eigene Inhalte auf diesen Seiten nach den allgemeinen
      Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
      Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
      gespeicherte fremde Informationen zu überwachen oder
      nach Umständen zu forschen, die auf eine rechtswidrige
      Tätigkeit hinweisen.
      Verpflichtungen zur Entfernung oder Sperrung der Nutzung
      von Informationen nach den allgemeinen Gesetzen bleiben
      hiervon unberührt. Eine diesbezügliche Haftung ist jedoch
      erst ab dem Zeitpunkt der Kenntnis einer konkreten
      Rechtsverletzung möglich. Bei Bekanntwerden von
      entsprechenden Rechtsverletzungen werden wir diese
      Inhalte umgehend entfernen.
    </p>
    <h3 class="text-2xl pt-4 relative group" style="padding-top: 16px">
      Haftung für Links
      <span class="absolute inset-x-0 bottom-1 bg-purple-600/60 h-2 w-36 rounded-lg z-[-1]" style="background-color: #ca0001;opacity:60%;width: 100%;border-radius:4px;position: absolute;bottom: 4px;height: 8px;left:0;z-index: -1"></span></h3>
    <p class="">
      Unser Angebot enthält Links zu externen Websites Dritter,
      auf deren Inhalte wir keinen Einfluss haben. Deshalb
      können wir für diese fremden Inhalte auch keine Gewähr
      übernehmen. Für die Inhalte der verlinkten Seiten ist stets
      der jeweilige Anbieter oder Betreiber der Seiten
      verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt
      der Verlinkung auf mögliche Rechtsverstöße überprüft.
      Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung
      nicht erkennbar.
      Eine permanente inhaltliche Kontrolle der verlinkten Seiten
      ist jedoch ohne konkrete Anhaltspunkte einer
      Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
      Rechtsverletzungen werden wir derartige Links umgehend
      entfernen.
    </p>
    <h3 class="text-2xl pt-4 relative group" style="padding-top: 16px">
      Urheberrecht
      <span class="absolute inset-x-0 bottom-1 bg-purple-600/60 h-2 w-36 rounded-lg z-[-1]" style="background-color: #ca0001;opacity:60%;width: 100%;border-radius:4px;position: absolute;bottom: 4px;height: 8px;left:0;z-index: -1"></span></h3>
    <p class="">
      Die durch die Seitenbetreiber erstellten Inhalte und Werke
      auf diesen Seiten unterliegen dem deutschen Urheberrecht.
      Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art
      der Verwertung außerhalb der Grenzen des Urheberrechtes
      bedürfen der schriftlichen Zustimmung des jeweiligen Autors
      bzw. Erstellers. Downloads und Kopien dieser Seite sind nur
      für den privaten, nicht kommerziellen Gebrauch gestattet.
      Soweit die Inhalte auf dieser Seite nicht vom Betreiber
      erstellt wurden, werden die Urheberrechte Dritter beachtet.
      Insbesondere werden Inhalte Dritter als solche
      gekennzeichnet. Sollten Sie trotzdem auf eine
      Urheberrechtsverletzung aufmerksam werden, bitten wir um
      einen entsprechenden Hinweis. Bei Bekanntwerden von
      Rechtsverletzungen werden wir derartige Inhalte umgehend
      entfernen.
      Quelle: eRecht24
    </p>
    <div class="p-12" style="padding: 48px"></div>
  </div>
</template>

<script>


export default {
  name: "impressum",

  setup() {

  },
  data() {
    return {

    }
  },
}
</script>
<style scoped>
h2{
  font-size: 36px;
  font-weight: bolder;
}
h3{
  font-size: 24px;
}
.relative{
  position: relative;
  width: fit-content;
}
</style>